import {
  GET_DRIVERS_DATA,
  GET_ADMIN_VEHICLE_COMPANIES,
  GET_ADMIN_VEHICLE_STATIONS,
} from "../constants/Constants";

const initialState = {
  drivers: {},
  adminVehiclesCompanies: {},
  adminVehiclesStations: {},
};

export const driversReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DRIVERS_DATA:
      return { ...state, drivers: action.payload };
    case GET_ADMIN_VEHICLE_COMPANIES:
      return { ...state, adminVehiclesCompanies: action.payload };
    case GET_ADMIN_VEHICLE_STATIONS:
      return { ...state, adminVehiclesStations: action.payload };
    default:
      return state;
  }
};
