export const SET_USER_DATA = "SET_USER_DATA";
export const GET_VEHICLES = "GET_VEHICLES";
export const GET_CHECKLIST = "GET_CHECKLIST";
export const GET_ADMINS = "GET_VEHICLE";
export const GET_SURVEY = "GET_SURVEY";
export const GET_COMPANIES = "GET_COMPANIES";
export const GET_TEMPLATE = "GET_TEMPLATE";
export const GET_COMPANY_FOR_ADMIN = "GET_COMPANY_FOR_ADMIN";
export const SET_VEHICLE_DETAIL = "SET_VEHICLE_DETAIL";
export const SET_VEHICLE_FILES = "SET_VEHICLE_FILES";

export const SET_VEHICLE_FILES_CATOGORIES = "SET_VEHICLE_FILES_CATOGORIES";

export const GET_PROFILE = "GET_PROFILE";
export const GET_ANALYTIC = "GET_ANALYTIC";
export const GET_VIN_DETAIL = "GET_VIN_DETAIL";
export const GET_COMPANY_DATA = "GET_COMPANY_DATA";
export const GET_USERS_DATA = "GET_USERS_DATA";
export const GET_ADMIN_VEHICLE_DATA = "GET_ADMIN_VEHICLE_DATA";
export const GET_ALL_ASSIGNEES = "GET_ALL_ASSIGNEES";
export const GET_InspectionAnalytic = "GET_InspectionAnalytic";
// export const GET_ALL_ASSIGNEES = "GET_ALL_ASSIGNEES";
export const SET_VEHICLE_FILE = "SET_VEHICLE_FILE";
export const GET_BlogData = "GET_BlogData";

export const Annotated = "Annotated";
export const GET_ADMIN_VEHICLE_COMPANIES = "GET_ADMIN_VEHICLE_COMPANIES";
export const GET_ADMIN_VEHICLE_STATIONS = "GET_ADMIN_VEHICLE_STATIONS";
export const UPDATE_VEHICLE_STATION = "UPDATE_VEHICLE_STATION";
export const FETCH_VEHICLE_INSPECTION_DATA_SUCCESS =
  "FETCH_VEHICLE_INSPECTION_DATA_SUCCESS";
export const UPDATE_VEHICLE_MILAGE_DATA_SUCCESS =
  "UPDATE_VEHICLE_MILAGE_DATA_SUCCESS";
export const GET_DRIVERS_DATA = "GET_DRIVERS_DATA";
export const GET_DASHBOARD_STATIONS = "GET_DASHBOARD_STATIONS";
