const initialState = {
  totalFleetsAnalytics: null,
  topFiveFleetsAnalytics: null,
  vehicleDetailAnalytics: null,
  barChartAnalytics: null,
  driverDetailAnalytics: null,
  vehicleActivityAnalytics: null,
};

const analyticsReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_TOTALFLEETS":
      return { ...state, totalFleetsAnalytics: action.payload };
    case "GET_TOPFIVEFLEETS":
      return { ...state, topFiveFleetsAnalytics: action.payload };
    case "GET_VEHICLEDETAIL":
      return { ...state, vehicleDetailAnalytics: action.payload };
    case "GET_BARCHART":
      return { ...state, barChartAnalytics: action.payload };
    case "GET_DRIVERDETAIL":
      return { ...state, driverDetailAnalytics: action.payload };
    case "GET_ACTIVITYDETAIL":
      return { ...state, vehicleActivityAnalytics: action.payload };
    default:
      return state;
  }
};

export default analyticsReducer;
